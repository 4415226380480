<template>
  <load-component v-if="!store.state.loaded"></load-component>
  <v-app class="bg-white" v-else>
    <div class="content">
      <v-container class="text-center justify-center">
        <v-sheet class="bg-transparent" height="10px"></v-sheet>

        <v-card width="333"  color="#DBDFE0" variant="outlined" class="text-center mx-auto" rounded="xl">
          <v-sheet class="bg-transparent" height="10px"></v-sheet>
          <v-card-item style="color: black" class="roboto-medium">
            {{ store.state.text[lang].balance.title_1 }}
          </v-card-item>
          <v-sheet class="bg-transparent" height="10px"></v-sheet>
          <v-card-title style="color: black; font-size: 48px" class="roboto-bold">
            <v-icon size="x-small" color="#AFBBC9">mdi-star-four-points</v-icon>
            {{ store.state.balance }}
          </v-card-title>
          <v-sheet class="bg-transparent" height="10px"></v-sheet>
          <v-card-item>
            <v-btn @click="openBottomSheet()" width="100%" class="border-12 text-none roboto-bold" height="48px" style="font-size: 17px" prepend-icon="mdi-plus-circle-outline" variant="flat" color="#F31549">
              {{ store.state.text[lang].balance.btn_1 }}
            </v-btn>
          </v-card-item>
          <v-sheet class="bg-transparent" height="10px"></v-sheet>
        </v-card>

        <v-sheet class="bg-transparent" height="30px"></v-sheet>


        <v-card variant="flat" class="text-start">
          <v-card-title class="roboto-bold">
            {{ store.state.text[lang].balance.title_2 }}
          </v-card-title>
        </v-card>

        <v-card variant="flat" color="#E4E7F3" class="border-12 py-1">
          <v-row no-gutters align="center">
            <v-col cols="6" class="px-1">
              <v-btn
                  v-if="!history_mode"
                  @click="changeMode"
                  width="100%"
                  class="border-12 text-none roboto"
                  height="32px"
                  style="font-size: 14px"
                  variant="flat"
                  color="white"
              >
                {{ store.state.text[lang].balance.deposit_history }}
              </v-btn>
              <v-btn
                  v-else
                  @click="changeMode"
                  width="100%"
                  class="border-12 text-none roboto"
                  height="32px"
                  style="font-size: 14px"
                  variant="flat"
                  color="#E4E7F3"
              >
                {{ store.state.text[lang].balance.deposit_history }}
              </v-btn>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-btn
                  v-if="history_mode"
                  @click="changeMode"
                  width="100%"
                  class="border-12 text-none roboto"
                  height="32px"
                  style="font-size: 14px"
                  variant="flat"
                  color="white"
              >
                {{ store.state.text[lang].balance.withdrawal_history }}
              </v-btn>
              <v-btn
                  v-else
                  @click="changeMode"
                  width="100%"
                  class="border-12 text-none roboto"
                  height="32px"
                  style="font-size: 14px"
                  variant="flat"
                  color="#E4E7F3"
              >
                {{ store.state.text[lang].balance.withdrawal_history }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>

        <v-sheet class="bg-transparent" height="20px"></v-sheet>

        <div v-if="!history_mode">
          <div class="my-1" v-for="payment in store.state.payment_history" :key="payment">
            <v-row align="center"  style="font-size: 14px" class="text-center">
              <v-col cols="auto">
                {{ payment.created_at }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col style="color: #A2ACB0" cols="auto">
                {{ payment.type }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                +{{ payment.amount }}
                <v-icon size="small" color="#F31549">mdi-star-four-points</v-icon>
              </v-col>
            </v-row>
            <hr>
          </div>
        </div>

        <div v-else>
          <div class="my-1" v-for="check in store.state.check_history" :key="check">
            <v-row align="center"  style="font-size: 14px" class="text-center">
              <v-col cols="auto">
                {{ checkedAt(check.created_at) }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                -1
                <v-icon size="small" color="#F31549">mdi-star-four-points</v-icon>
              </v-col>
            </v-row>
            <hr>
          </div>
        </div>

        <v-sheet class="bg-transparent" height="130px"></v-sheet>
      </v-container>
    </div>

    <vue-bottom-sheet :max-height="500" ref="myBottomSheet">
      <v-row align="start" no-gutters="" class="my-0 py-0">
        <v-spacer></v-spacer>
        <v-col cols="auto" class="mr-4">
          <v-btn @click="closeBottomSheet" width="28" height="28" size="small" icon="mdi-close" class="rounded-xl" color="#F5F5F5" variant="flat">
          </v-btn>
        </v-col>
      </v-row>

      <v-card variant="flat">
        <v-card-item>
          <h3 class="roboto-bold">{{ store.state.text[lang].balance.title_3 }}</h3>
          <v-sheet height="10px"></v-sheet>
          <v-sheet class="bg-transparent" height="10px"></v-sheet>
          <v-text-field
              prepend-icon="mdi-star-four-points"
              type="number" inputmode="decimal" pattern="[0-9]+([\.,][0-9]+)?" :step="0.01"
              :clearable="true"
              rounded="lg"
              @keyup.enter="hideKeyboard"
              variant="outlined"
              v-model="input_amount"
              :placeholder="store.state.text[lang].balance.input_placeholder"
          >
          </v-text-field>
          <v-sheet height="130px"></v-sheet>
        </v-card-item>
        <v-card-item>
          <v-btn @click="pushToPaymentPage()" width="100%" class="border-12 text-none roboto-medium" height="48px" style="font-size: 14px" variant="flat" color="#F31549">
            {{ store.state.text[lang].balance.btn_2 }}
            <svg class="mx-1" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7428 0.781669C11.2421 -0.260556 9.75804 -0.260557 9.25734 0.781668L7.2736 4.91091C7.07262 5.32926 6.67464 5.61841 6.21466 5.68028L1.67451 6.29092C0.528573 6.44504 0.0699783 7.85645 0.906469 8.6547L1.73754 9.44779C5.02718 11.6952 9.94336 10.1317 10.8199 9.82506C10.8869 9.80162 10.9784 9.80385 11.0187 9.86234C11.0752 9.94453 11.0337 10.0733 10.9406 10.1092C10.046 10.4545 4.61408 13.5111 4.21072 15.3333L3.80285 17.5689C3.59532 18.7063 4.79593 19.5786 5.81361 19.0298L9.8456 16.8551C10.2541 16.6348 10.746 16.6348 11.1545 16.8551L15.1865 19.0298C16.2042 19.5786 17.4048 18.7063 17.1973 17.5689L16.375 13.0622C16.2917 12.6056 16.4437 12.1378 16.7795 11.8174L20.0936 8.6547C20.9301 7.85645 20.4715 6.44504 19.3256 6.29092L14.7854 5.68028C14.3255 5.61841 13.9275 5.32926 13.7265 4.91091L11.7428 0.781669Z" fill="white"/>
            </svg>
            150
          </v-btn>
          <v-sheet height="10px"></v-sheet>
          <v-btn @click="createCryptoInvoice()" width="100%" class="border-12 text-none roboto-medium" height="48px" style="font-size: 14px" variant="flat" color="#353748">
            Pay with crypto
          </v-btn>
          <v-sheet height="150px"></v-sheet>
        </v-card-item>
      </v-card>

    </vue-bottom-sheet>

    <vue-bottom-sheet :max-height="700" ref="myBottomSheet2">
      <v-row align="start" no-gutters="" class="my-0 py-0">
        <v-spacer></v-spacer>
        <v-col cols="auto" class="mr-4">
          <v-btn @click="closeSuccessBottomSheet" width="28" height="28" size="small" icon="mdi-close" class="rounded-xl" color="#F5F5F5" variant="flat">
          </v-btn>
        </v-col>
      </v-row>

      <v-card variant="flat" class="text-center">
        <v-card-item>
          <h3 class="roboto-bold">
            The purchase was a successfull!
          </h3>
        </v-card-item>

        <v-card-item>
          <svg width="323" height="279" viewBox="0 0 323 279" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_31_4431)">
              <circle cx="176.5" cy="151.5" r="99.5" fill="white"/>
            </g>
            <path d="M147 127C147 137.405 158.617 149.654 169 149.654C158.217 149.654 147 161.776 147 172.308C147 161.708 135.083 149.654 125 149.654C135.483 149.654 147 137.405 147 127Z" fill="#F31549"/>

            <svg x="180" y="130" :width="200" :height="100">
              <text
                  v-for="(digit, index) in success_payment_amount"
                  :key="index"
                  :x="index * 25"
                  :y="52 * 0.7"
                  font-family="San Francisco, Arial, sans-serif"
                  :font-size="42"
                  fill="black"
              >
                {{ digit }}
              </text>
            </svg>

            <rect x="273" y="94.8027" width="11.4775" height="5.73877" transform="rotate(-30.2902 273 94.8027)" fill="#FE8A3D"/>
            <rect x="270.066" y="146.78" width="11.4775" height="5.73877" transform="rotate(-110.74 270.066 146.78)" fill="#FE8A3D"/>
            <rect opacity="0.2" x="303" y="71.707" width="11.4775" height="5.73877" transform="rotate(-68.695 303 71.707)" fill="#FE8A3D"/>
            <rect opacity="0.2" x="313" y="108.707" width="11.4775" height="5.73877" transform="rotate(-68.695 313 108.707)" fill="#FE8A3D"/>
            <rect x="317.348" y="40.8057" width="11.4775" height="5.73877" transform="rotate(-112.885 317.348 40.8057)" fill="#FE8A3D"/>
            <rect opacity="0.5" x="272.781" y="50.0137" width="11.4775" height="5.73877" transform="rotate(28.9944 272.781 50.0137)" fill="#FE8A3D"/>
            <rect opacity="0.5" x="241.781" y="63.0137" width="11.4775" height="5.73877" transform="rotate(28.9944 241.781 63.0137)" fill="#FE8A3D"/>
            <rect x="71.6094" y="59.0649" width="11.4775" height="5.73877" transform="rotate(98.5041 71.6094 59.0649)" fill="#FE8A3D"/>
            <rect x="32.9336" y="24.2134" width="11.4775" height="5.73877" transform="rotate(18.0539 32.9336 24.2134)" fill="#FE8A3D"/>
            <rect opacity="0.2" x="70.8125" y="96.917" width="11.4775" height="5.73877" transform="rotate(60.0994 70.8125 96.917)" fill="#FE8A3D"/>
            <rect opacity="0.2" x="35.7109" y="81.5298" width="11.4775" height="5.73877" transform="rotate(60.0994 35.7109 81.5298)" fill="#FE8A3D"/>
            <rect x="85.9102" y="127.46" width="11.4775" height="5.73877" transform="rotate(15.9089 85.9102 127.46)" fill="#FE8A3D"/>
            <rect opacity="0.5" x="106.652" y="86.9561" width="11.4775" height="5.73877" transform="rotate(157.789 106.652 86.9561)" fill="#FE8A3D"/>
            <rect opacity="0.5" x="115.945" y="54.6499" width="11.4775" height="5.73877" transform="rotate(157.789 115.945 54.6499)" fill="#FE8A3D"/>
            <defs>
              <filter id="filter0_d_31_4431" x="53" y="32" width="247" height="247" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="12"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_31_4431"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_31_4431" result="shape"/>
              </filter>
            </defs>
          </svg>

        </v-card-item>
        <v-card-item class="text-center">
          <p>You've acquired new checks</p>
        </v-card-item>
        <v-card-item>
          <v-btn @click="closeSuccessBottomSheet" width="100%" class="border-12 text-none roboto-bold" height="48px" variant="flat" color="#F31549">
            <b>I got it</b>
          </v-btn>
        </v-card-item>
      </v-card>
      <v-sheet height="30px"></v-sheet>

    </vue-bottom-sheet>

    <bottom-nav-component btn_3_color="#F31549" :lang="lang"></bottom-nav-component>
  </v-app>
</template>

<script>
import axios from "axios";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import  "@webzlodimir/vue-bottom-sheet/dist/style.css";
import BottomNavComponent from "@/components/BottomNavComponent.vue";
import store from "../store";
import LoadComponent from "@/components/LoadComponent.vue";
export default {
  name: "BalancePage",
  computed: {
    store() {
      return store
    }
  },
  components: {
    LoadComponent,
    BottomNavComponent,
    VueBottomSheet
  },
  data: () => ({
    balance: 0,
    history_mode: false,
    user_id: null,
    sheet: false,
    lang: "en",
    input_amount: 0,
    success_payment_amount: "0"
  }),
  mounted() {

    window.Telegram.WebApp.onEvent('invoiceClosed', (data) => {
      if (data.status == "paid") {
        this.success_payment_amount = `${this.input_amount}`
        setTimeout(this.openSuccessBottomSheet, 500)
      }
    })
    if (localStorage.getItem('lang')) {
      this.lang = localStorage.getItem('lang')
    }

    if (this.$route.query.success_payment) {
      this.success_payment_amount = this.$route.query.success_payment_amount
      setTimeout(this.openSuccessBottomSheet, 500)
    }

    if (!store.state.loaded) {
      this.checkInitData({_auth: window.Telegram.WebApp.initData})
    }
  },
  methods: {
    checkInitData(data) {
      axios.post('api/v1/check-init-data/', data)
          .then(response => {
            this.$store.commit("setStateProperty", {
              property: "user", value: response.data.user
            })
            this.loadUserData(response.data.user.id)
          })
          .catch(error => {
            console.error(error);
          });
    },
    loadUserData(user_id) {
      this.getUser(user_id)
      this.getCheckResultHistory(user_id)
      this.getPaymentHistory(user_id)
      this.getReferrals(user_id)
      this.$store.commit("setStateProperty", {
        property: "loaded", value: true
      })
      this.loading = false
      window.Telegram.WebApp.ready()
    },
    getUser(user_id) {
      axios.post('api/v1/getUser', {user_id: user_id})
          .then(response => {
            this.$store.commit("setStateProperty", {
              property: "balance", value: response.data.balance
            })
          })
          .catch(error => {
            console.error(error);
          });
    },
    getCheckResultHistory(user_id) {
      axios.post('api/v1/check-result/history/', {user_id: user_id})
          .then(response => {
            this.$store.commit("setStateProperty", {
              property: "check_history", value: response.data
            })
          })
          .catch(error => {
            console.error(error);
          });
    },
    getReferrals(user_id) {
      axios.post('api/v1/user/get-referrals/', {user_id: user_id})
          .then(response => {
            this.$store.commit("setStateProperty", {
              property: "friends", value: response.data
            })
          })
          .catch(error => {
            console.error(error);
          });
    },
    getPaymentHistory(user_id) {
      axios.post('api/v1/payment/history/', {user_id: user_id})
          .then(response => {
            this.$store.commit("setStateProperty", {
              property: "payment_history", value: response.data
            })
          })
          .catch(error => {
            console.error(error);
          });
    },
    changeMode() {
      this.history_mode = !this.history_mode
    },
    hideKeyboard(event) {
      event.preventDefault();
      event.target.blur();
    },
    openBottomSheet() {
      window.Telegram.WebApp.HapticFeedback.selectionChanged()
      this.$refs.myBottomSheet.open();
    },
    closeBottomSheet() {
      this.$refs.myBottomSheet.close();
    },
    openSuccessBottomSheet() {
      this.$refs.myBottomSheet2.open();
    },
    closeSuccessBottomSheet() {
      this.$router.push({name: 'Balance'});
      this.$refs.myBottomSheet2.close();
    },
    createTelegramInvoice(callback) {
      axios.post('api/v1/payment/create-telegram-invoice/', {amount: this.input_amount})
          .then(response => {
            callback(response.data);
          })
          .catch(error => {
            console.error(error);
          });
    },
    createCryptoInvoice() {
      store.state.loaded = false
      axios.post('api/v1/payment/create-crypto-invoice/', {
        amount: this.input_amount,
        user_id: store.state.user.id
      })
          .then(response => {
            this.$router.push({name: 'CryptoPayment', query: {invoice_id: response.data.id}});
            store.state.loaded = true
          })
          .catch(error => {
            store.state.loaded = true
            console.error(error);
          });
    },
    checkedAt(data) {
      const date = new Date(data);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return`${day}.${month}.${year} ${hours}:${minutes}`;
    },
    pushToPaymentPage() {
      this.createTelegramInvoice( response => {
        window.Telegram.WebApp.openInvoice(response["invoice_link"]);
      });
    },
    pushToCryptoPaymentPage() {
      this.$router.push({name: 'CryptoPayment'});
    },
  }
}

</script>

<style scoped>

.time-left {
  position: absolute;
  top: 10px;
  width: 100%;
}

.pre-subtitle {
  white-space: pre-line !important;
}


</style>