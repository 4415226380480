<template>
  <v-app class="bg-white text-center">
    <div class="content">
      <v-container>
        <v-sheet class="bg-transparent" height="100px"></v-sheet>
        <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" width="64" height="64" rx="14.8624" fill="#F5F6FB"/>
          <path d="M31.8765 18.4888C29.2042 18.4888 26.592 19.2812 24.37 20.7658C22.1481 22.2505 20.4164 24.3606 19.3937 26.8295C18.3711 29.2984 18.1035 32.015 18.6249 34.6359C19.1462 37.2569 20.433 39.6643 22.3226 41.5539C24.2122 43.4435 26.6197 44.7303 29.2406 45.2517C31.8615 45.773 34.5782 45.5054 37.047 44.4828C39.5159 43.4602 41.6261 41.7284 43.1107 39.5065C44.5953 37.2846 45.3878 34.6723 45.3878 32C45.3878 30.2257 45.0383 28.4688 44.3593 26.8295C43.6803 25.1902 42.685 23.7008 41.4304 22.4461C40.1758 21.1915 38.6863 20.1963 37.047 19.5173C35.4078 18.8382 33.6508 18.4888 31.8765 18.4888V18.4888ZM31.8765 42.809C29.7387 42.809 27.6489 42.1751 25.8713 40.9874C24.0938 39.7997 22.7084 38.1115 21.8903 36.1365C21.0722 34.1614 20.8581 31.988 21.2752 29.8913C21.6923 27.7946 22.7217 25.8686 24.2334 24.3569C25.745 22.8452 27.671 21.8158 29.7678 21.3987C31.8645 20.9816 34.0378 21.1957 36.0129 22.0138C37.988 22.8319 39.6762 24.2173 40.8639 25.9949C42.0516 27.7724 42.6855 29.8622 42.6855 32C42.6855 34.8668 41.5467 37.6161 39.5196 39.6432C37.4925 41.6702 34.7432 42.809 31.8765 42.809V42.809ZM31.8765 23.8933C31.5182 23.8933 31.1745 24.0356 30.9211 24.289C30.6677 24.5424 30.5254 24.8861 30.5254 25.2444V31.2164L27.688 32.8512C27.4277 32.9987 27.2238 33.2284 27.1081 33.5043C26.9924 33.7802 26.9716 34.0867 27.0489 34.3758C27.1261 34.6648 27.2971 34.92 27.5351 35.1013C27.773 35.2827 28.0644 35.3799 28.3636 35.3778C28.6003 35.3795 28.8332 35.3189 29.0391 35.2022L32.5521 33.1755L32.6737 33.0539L32.8898 32.8783C32.9427 32.8114 32.988 32.7389 33.025 32.6621C33.069 32.5895 33.1052 32.5126 33.133 32.4324C33.1698 32.3465 33.1926 32.2553 33.2006 32.1622C33.2006 32.1622 33.2276 32 33.2276 32V25.2444C33.2276 24.8861 33.0853 24.5424 32.8319 24.289C32.5785 24.0356 32.2348 23.8933 31.8765 23.8933Z" fill="#333549"/>
        </svg>
        <v-sheet class="bg-transparent" height="30px"></v-sheet>
        <h2 class="roboto-bold">Time expired</h2>
        <p> To continue payment, start again</p>
        <v-sheet class="bg-transparent" height="30px"></v-sheet>
        <v-btn @click="pushToHomePage" width="80%" class="border-12 text-none roboto-medium" height="48px" variant="flat" color="#F31549">
          Try again
        </v-btn>
        <v-sheet class="bg-transparent" height="100px"></v-sheet>
      </v-container>
    </div>
  </v-app>

</template>

<script>

export default {
  name: "PaymentExpiredPage",
  components: {
  },
  data: () => ({
    lang: "en",
  }),
  mounted() {
    window.Telegram.WebApp.onEvent('backButtonClicked', () => {
      this.$router.push({ name: 'Home'});
    })
    window.Telegram.WebApp.BackButton.show()

    if (localStorage.getItem('lang')) {
      this.lang = localStorage.getItem('lang')
    }
  },
  unmounted() {
    window.Telegram.WebApp.BackButton.offClick()
    window.Telegram.WebApp.BackButton.hide()
  },
  methods: {
    pushToHomePage() {
      this.$router.push({ name: 'Home'});
    }
  }
}
</script>

<style scoped>

@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

html, body {
  font-family: 'SF Pro', sans-serif !important;
  color: black;
  background: white !important;
  touch-action: manipulation !important;
}

.pre-subtitle {
  white-space: pre-line !important;
}

</style>