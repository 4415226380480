import { createStore } from 'vuex';
const store = createStore({
  state () {
    return {
      loaded: false,
      user: null,
      check_history: [],
      payment_history: [],
      balance: 0,
      friends: [],
      text: {
        ru: {
          confirm_check_again: "Сделать проверку еще раз?",
          success_copy_text: "Текст успешно скопирован",
          language: {
            title: "Выберите язык",
            button: "Далее"
          },
          preview: {
            title_1: "Проверьте чистоту криптовалюты",
            desc_1: "Просто введите адрес вашего кошелька\n и получите оценку криптовалюты",
            title_2: "Получите\nподробный отчет",
            desc_2: "Подробный криптоотчет с рисками и положительными показателями",
            button_1: "Пропустить",
            button_2: "Далее"
          },
          nav: {
            main: "Главная",
            history: "История",
            balance: "Баланс",
            friends: "Друзья",
            settings: "Настройки"
          },
          home: {
            blockchain_network_error: "Выберите сеть блокчейна",
            check_input_label: "Проверить Адрес",
            check_input_placeholder: "Введите адрес",
            valid_error: "Пожалуйста введите корректный адрес",
            specify_blockchain: "Выберите блокчейн",
            check_btn: "Проверить"
          },
          check_result: {
            title_1: "Результат проверки AML",
            subtitle_1: "Обновлено",
            subtitle_2: "Стоимость",
            risk_low: "Низкий риск",
            risk_medium: "Средний риск",
            risk_high: "Высоки риск",
            title_2: "Риски адреса",
            btn_1: "Повторить",
            btn_2: "Скачать PDF",
            title_3: "Все чисто"
          },
          check_history: {
            title: "История запросов",
            date: "Дата",
            apply_date_btn: "Принять"
          },
          balance: {
            title_1: "Баланс",
            btn_1: "Пополнить",
            title_2: "История пополнений",
            title_3: "Пополнение баланса",
            deposit_history: "Пополнения",
            withdrawal_history: "Расходы",
            btn_2: "Оплатить Telegram Stars",
            input_placeholder: "Введите сумму"
          },
          friends: {
            title_1: "Приглашайте друзей",
            desc: "Приглашайте друзей и получайте токены",
            title_2: "Баланс",
            title_3: "Ваша реферальная ссылка",
            btn_1: "Пригласить друзей",
            btn_2: "Друзья",
            btn_3: "История",
            title_4: "Ваши друзья",

          },
          settings: {
            title: "Настройки",
            cooldown: "Пожалуйста, подождите перед сменой языка"
          },
        },
        en: {
          confirm_check_again: "Check again?",
          success_copy_text: "Text copied successfully",
          language: {
            title: "Select a language",
            button: "Next"
          },
          preview: {
            title_1: "Check the purity of your crypto",
            desc_1: "Just enter your wallet address and get a crypto valuation",
            title_2: "Get a detailed report",
            desc_2: "Detailed crypto report with risk and positive<br> indicators",
            button_1: "Skip",
            button_2: "Next"
          },
          nav: {
            main: "Main",
            history: "History",
            balance: "Balance",
            friends: "Friends",
            settings: "Settings"
          },
          home: {
            blockchain_network_error: "Select a blockchain network",
            check_input_label: "Check Address",
            check_input_placeholder: "Enter address",
            valid_error: "Please enter a valid address",
            specify_blockchain: "Specify Blockchain",
            check_btn: "Check"
          },
          check_result: {
            title_1: "AML Check Result",
            subtitle_1: "Updated",
            subtitle_2: "Check cost",
            risk_low: "Low risk",
            risk_medium: "Medium risk",
            risk_high: "High risk",
            title_2: "Address exposure",
            btn_1: "Check again",
            btn_2: "Download PDF",
            title_3: "All clear"
          },
          check_history: {
            title: "Request history",
            date: "Date",
            apply_date_btn: "Apply"
          },
          balance: {
            title_1: "Balance",
            btn_1: "Top up",
            title_2: "Payment history",
            title_3: "Top up balance",
            deposit_history: "Deposit History",
            withdrawal_history: "Withdrawal History",
            btn_2: "Pay with Telegram Stars",
            input_placeholder: "Enter amount"
          },
          friends: {
            title_1: "Invite friends",
            desc: "Invite frens to get tokens",
            title_2: "Token balance",
            title_3: "Your referral link",
            btn_1: "Invite friends",
            btn_2: "Frens",
            btn_3: "History",
            title_4: "Your frens"
          },
          settings: {
            title: "Settings",
            cooldown: "Please wait before changing the language"
          },
        }
      }
    }
  },
  mutations: {
    setStateProperty(state, { property, value }) {
      state[property] = value;
    },
  },
  getters: {
    doneTodos (state) {
      return state.tex.filter(todo => todo.done)
    }
  }
});

export default store;

