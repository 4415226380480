<template>
  <v-app class="bg-white text-center">
    <v-container class="text-center">
      <v-sheet height="200px"></v-sheet>
      <v-progress-circular
          color="black"
          size="100"
          width="10"
          indeterminate
      ></v-progress-circular>
    </v-container>

  </v-app>

</template>

<script>
export default {
  name: "LoadComponent",
  components: {
  },
  data: () => ({
  }),
  mounted() {
    // window.Telegram.WebApp.ready()
    // window.Telegram.WebApp.BackButton.show()
    // window.Telegram.WebApp.MainButton.hide()
  }
}
</script>

<style scoped>

</style>