<template>
  <v-app class="bg-color">
    <v-sheet class="bg-transparent" style="height: 30vh"></v-sheet>
    <v-card class="bg-transparent" variant="flat">
      <v-card-title class="text-center" style="font-size: 24px">
        <b>{{ store.state.text[lang].language.title }}</b>
      </v-card-title>
      <v-card-item>
        <v-card variant="flat" @click="onChange" class="py-2 px-4 border-12">
          <v-row  no-gutters="" align="center" justify="center">
            <v-col cols="1">
              <svg width="28" height="28" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_308_6838)">
                  <rect width="24" height="18" rx="4" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0V18H24V0H0Z" fill="#2E42A5"/>
                  <mask id="mask0_308_6838" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="18">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0V18H24V0H0Z" fill="white"/>
                  </mask>
                  <g mask="url(#mask0_308_6838)">
                    <path d="M-2.67212 16.7137L2.60915 18.9473L24.1201 2.42805L26.9058 -0.89106L21.2583 -1.63758L12.4845 5.48103L5.42247 10.2773L-2.67212 16.7137Z" fill="white"/>
                    <path d="M-1.94922 18.2785L0.741343 19.5747L25.9054 -1.1995H22.1275L-1.94922 18.2785Z" fill="#F50100"/>
                    <path d="M26.6721 16.7137L21.3909 18.9473L-0.120075 2.42805L-2.90582 -0.89106L2.74173 -1.63758L11.5155 5.48103L18.5775 10.2773L26.6721 16.7137Z" fill="white"/>
                    <path d="M26.4919 17.8371L23.8014 19.1333L13.0863 10.2388L9.90948 9.24502L-3.17385 -0.879377H0.604015L13.68 9.00471L17.1533 10.1963L26.4919 17.8371Z" fill="#F50100"/>
                    <mask id="path-8-inside-1_308_6838" fill="white">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8338 -1.50049H9.16711V5.99951H-1.479V11.9995H9.16711V19.4995H14.8338V11.9995H25.521V5.99951H14.8338V-1.50049Z"/>
                    </mask>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8338 -1.50049H9.16711V5.99951H-1.479V11.9995H9.16711V19.4995H14.8338V11.9995H25.521V5.99951H14.8338V-1.50049Z" fill="#F50100"/>
                    <path d="M9.16711 -1.50049V-3.00049H7.66711V-1.50049H9.16711ZM14.8338 -1.50049H16.3338V-3.00049H14.8338V-1.50049ZM9.16711 5.99951V7.49951H10.6671V5.99951H9.16711ZM-1.479 5.99951V4.49951H-2.979V5.99951H-1.479ZM-1.479 11.9995H-2.979V13.4995H-1.479V11.9995ZM9.16711 11.9995H10.6671V10.4995H9.16711V11.9995ZM9.16711 19.4995H7.66711V20.9995H9.16711V19.4995ZM14.8338 19.4995V20.9995H16.3338V19.4995H14.8338ZM14.8338 11.9995V10.4995H13.3338V11.9995H14.8338ZM25.521 11.9995V13.4995H27.021V11.9995H25.521ZM25.521 5.99951H27.021V4.49951H25.521V5.99951ZM14.8338 5.99951H13.3338V7.49951H14.8338V5.99951ZM9.16711 -0.000488281H14.8338V-3.00049H9.16711V-0.000488281ZM10.6671 5.99951V-1.50049H7.66711V5.99951H10.6671ZM-1.479 7.49951H9.16711V4.49951H-1.479V7.49951ZM0.0209961 11.9995V5.99951H-2.979V11.9995H0.0209961ZM9.16711 10.4995H-1.479V13.4995H9.16711V10.4995ZM10.6671 19.4995V11.9995H7.66711V19.4995H10.6671ZM14.8338 17.9995H9.16711V20.9995H14.8338V17.9995ZM13.3338 11.9995V19.4995H16.3338V11.9995H13.3338ZM25.521 10.4995H14.8338V13.4995H25.521V10.4995ZM24.021 5.99951V11.9995H27.021V5.99951H24.021ZM14.8338 7.49951H25.521V4.49951H14.8338V7.49951ZM13.3338 -1.50049V5.99951H16.3338V-1.50049H13.3338Z" fill="white" mask="url(#path-8-inside-1_308_6838)"/>
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_308_6838">
                    <rect width="24" height="18" rx="4" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </v-col>
            <v-col class="ml-2" cols="4">
              English (EN)
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="1">
              <v-radio color="#F31549" v-model="radio_1" true-icon="mdi-check-circle-outline"></v-radio>
            </v-col>
          </v-row>
        </v-card>
        <v-sheet class="bg-transparent" height="10px"></v-sheet>
        <v-card variant="flat" @click="onChange2" class="py-2 px-4 border-12" >
          <v-row  no-gutters="" align="center" justify="center">
            <v-col cols="1">
              <svg width="28" height="28" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_308_6845)">
                  <rect width="24" height="18" rx="4" fill="white"/>
                  <mask id="mask0_308_6845" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="-1" width="24" height="19">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 -0.600098V17.3999H24V-0.600098H0Z" fill="white"/>
                  </mask>
                  <g mask="url(#mask0_308_6845)">
                    <rect y="5.3999" width="24" height="6" fill="#3D58DB"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 -0.600098V5.3999H24V-0.600098H0Z" fill="#F7FCFF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 11.3999V17.3999H24V11.3999H0Z" fill="#C51918"/>
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_308_6845">
                    <rect width="24" height="18" rx="4" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

            </v-col>
            <v-col class="ml-2" cols="4">
              Русский (RU)
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="1">
              <v-radio color="#F31549" v-model="radio_2" true-icon="mdi-check-circle-outline"></v-radio>
            </v-col>
          </v-row>
        </v-card>
        <v-sheet class="bg-transparent" height="10px"></v-sheet>
      </v-card-item>
    </v-card>

    <v-bottom-navigation
        elevation="0"
        class="bg-transparent"
        style="height: 90px"
        grow
    >
      <v-container class="p-3">
        <v-card @click="pushToPreviewPage" class="border-12" color="#F31549" variant="flat" width="100%">
          <v-card-item class="text-center align-center" style="font-size: 16px">
            <b>{{ store.state.text[lang].language.button }}</b> <v-icon size="small">mdi-chevron-right</v-icon>
          </v-card-item>
        </v-card>
      </v-container>
    </v-bottom-navigation>

  </v-app>

</template>

<script>


import store from "../store";

export default {
  name: "LanguagePage",
  computed: {
    store() {
      return store
    }
  },
  components: {},
  data: () => ({
    lang: "en",
    radio_1: true,
    radio_2: false
  }),
  mounted() {
    this.lang = localStorage.getItem("lang")
    if (this.lang == "ru") {
      this.radio_1 = false
      this.radio_2 = true
    }

    window.Telegram.WebApp.ready()
    window.Telegram.WebApp.BackButton.hide()
    window.Telegram.WebApp.MainButton.hide()
    window.Telegram.WebApp.disableVerticalSwipes()
    window.Telegram.WebApp.expand()
  },
  methods: {
    onChange() {
      localStorage.setItem("lang", "en")
      this.lang = "en"
      this.radio_1 = true
      this.radio_2 = false
    },
    onChange2() {
      localStorage.setItem("lang", "ru")
      this.lang = "ru"
      this.radio_1 = false
      this.radio_2 = true
    },
    pushToPreviewPage() {
      this.$router.push({name: 'Preview'});
    }
  }
}
</script>

<style scoped>
html, body {
  font-family: 'SF Pro', sans-serif !important;
  background: linear-gradient(to bottom, #FFBCA9, #FFE2B2, #FFFFFF, );
  touch-action: manipulation !important;
}

.bg-color {
  background: linear-gradient(45deg, #FFFBDF 1%, #FFBCA9 20%, #FFE2B2, #FFFFFF);
}


</style>