<template>

  <v-app class="bg-white">
    <div class="content">
      <v-container>
        <v-sheet class="bg-transparent" height="30px"></v-sheet>
        <h2 class="roboto-bold">Make payment</h2>
        <p>Choose a cryptocurrency and pay by the specified details or Qr-code</p>
        <v-sheet class="bg-transparent" height="20px"></v-sheet>
        <v-select
            color="black"
            bg-color="white"
            label="Currency"
            rounded="lg"
            :items="items"
            item-title="title"
            item-value="value"
            v-model="currency"
            variant="outlined"
            menu-icon="mdi-chevron-down"
            hide-details
        ></v-select>
        <v-sheet class="bg-transparent" height="20px"></v-sheet>
        <a style="color: #858993">To pay</a>
        <h3 class="roboto-bold">{{ crypto_payment.amount }} {{currency}}</h3>

        <v-sheet class="bg-transparent" height="20px"></v-sheet>
        <a style="color: #858993">Address {{ currency }}</a>

        <v-card class="px-3 py-3 text-center border-12" width="100%" variant="flat" color="#F5F6FB">

          <v-card-item @click="copyData(crypto_payment.address)" class="pre-subtitle bg-white border-12" style="font-size: 15px; line-height: 21px">
            <v-row align="center">
              <v-col cols="10">
                {{ crypto_payment.address }}
              </v-col>
              <v-col cols="2">
                <v-icon>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.5 6.75C4.5 5.50736 5.50736 4.5 6.75 4.5H15.75C16.9926 4.5 18 5.50736 18 6.75V15.75C18 16.9926 16.9926 18 15.75 18H6.75C5.50736 18 4.5 16.9926 4.5 15.75V6.75Z" fill="#858793"/>
                    <path d="M2.25 0C1.00736 0 0 1.00736 0 2.25V11.25C0 12.4926 1.00736 13.5 2.25 13.5L2.25 6.75C2.25 4.26472 4.26472 2.25 6.75 2.25H13.5C13.5 1.00736 12.4926 0 11.25 0H2.25Z" fill="#858793"/>
                  </svg>
                </v-icon>
              </v-col>
            </v-row>
          </v-card-item>
          <v-card-item>
            <v-img class="mx-auto" width="80%" :src="crypto_payment.qr">
            </v-img>
          </v-card-item>
        </v-card>
        <v-sheet class="bg-transparent" height="100px"></v-sheet>

      </v-container>
    </div>

    <v-bottom-navigation
        height="150px"
        base-color="#1A1C25"
        color="white"
    >
      <v-container>
        <v-row justify="center" no-gutters>
          <h5 class="roboto-bold">Payment to be made


            <a style="color: #E13636" v-if="timer_minutes < 5">
              <a v-if="timer_minutes < 10">0</a>{{ timer_minutes }}:<a v-if="timer_seconds < 10">0</a>{{ timer_seconds }}
            </a>
            <a style="color: #FF8229" v-else>
              <a v-if="timer_minutes < 10">0</a>{{ timer_minutes }}:<a v-if="timer_seconds < 10">0</a>{{ timer_seconds }}
            </a>
          </h5>
          <a style="color: #858993">After 20 minutes, the order will be canceled</a>
        </v-row>
      </v-container>

    </v-bottom-navigation>
  </v-app>

  <v-snackbar
      style="margin-bottom: 100px"
      v-model="success_snackbar_show"
      :timeout="snackbar_timeout"
      color="#1d1d1d"
      rounded="lg"
      location="center"
      variant="flat"
  >
    ✅ {{ success_snackbar_text }}
  </v-snackbar>
</template>

<script>
import axios from "axios";
// import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader';
// import LoadComponent from "@/components/LoadComponent.vue";
import store from "../store";

export default {
  name: "BottomNavPage",
  components: {
    // LoadComponent,
  },
  data: () => ({
    items: ["TON", "USDT"],
    lang: "en",
    currency: null,
    crypto_payment: {
      amount: 0,
      address: "UQB3hP_ghvmsN_Tv83Xq4xfMSyUfm93pmrc4XVaQ2TfK3-BR",
    },
    success_snackbar_text: null,
    success_snackbar_show: false,
    snackbar_timeout: 3000,
    timer_minutes: 0,
    timer_seconds: 0,
    fontSize: 42,
    fontFamily: 'San Francisco, Arial, sans-serif',
    width: 200,
    height: 100,
    update_timer: null,
    update_payment_timer: null
  }),
  mounted() {
    this.currency = this.items[0]
    if (localStorage.getItem('lang')) {
      this.lang = localStorage.getItem('lang')
    }

    this.getInvoice(this.$route.query.invoice_id)
    // if (!store.state.loaded) {
    //   this.checkInitData({_auth: window.Telegram.WebApp.initData})
    // }
  },
  beforeUnmount () {
    clearInterval(this.update_timer)
    clearInterval(this.update_payment_timer)
  },
  computed: {
    store() {
      return store
    },
  },
  methods: {
    // checkInitData(data) {
    //   axios.post('api/v1/check-init-data/', data)
    //       .then(response => {
    //         // this.$store.commit("setStateProperty", {
    //         //   property: "user", value: response.data.user
    //         // })
    //         this.getInvoice(this.$route.query.invoice_id)
    //         // this.loadUserData(response.data.user.id)
    //       })
    //       .catch(error => {
    //         console.error(error);
    //       });
    // },

    updateTimer() {
      const date = new Date(this.crypto_payment.expired_at);
      const now = new Date();
      const diff = date - now;

      this.timer_minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      this.timer_seconds = Math.floor((diff % (1000 * 60)) / 1000);

      if (this.timer_minutes == 0) {
        this.$router.push({
          name: 'CryptoPaymentExpired',
        });
      }
    },
    updatePaymentTimer() {
      this.getInvoice(this.$route.query.invoice_id)
    },
    loadUserData(user_id) {
      this.getUser(user_id)
      this.getCheckResultHistory(user_id)
      this.getPaymentHistory(user_id)
      this.$store.commit("setStateProperty", {
        property: "loaded", value: true
      })
      this.loading = false
      window.Telegram.WebApp.ready()
    },
    getInvoice(invoice_id) {
      axios.get('api/v1/payment/get-crypto-invoice/', {
        params: {
          invoice_id: invoice_id
        }
      }).then(response => {
            this.crypto_payment = response.data
            this.update_timer = setInterval(this.updateTimer, 1000);
            this.update_payment_timer = setInterval(this.updatePaymentTimer, 180000);

            if (this.crypto_payment.status == "paid") {
              this.$router.push({
                name: 'Balance', query: {
                  success_payment: "true",
                  success_payment_amount: this.crypto_payment.amount
                }
              });
            }
          })
          .catch(error => {
            console.error(error);
          });
    },
    getUser(user_id) {
      axios.post('api/v1/getUser', {user_id: user_id})
          .then(response => {
            this.$store.commit("setStateProperty", {
              property: "balance", value: response.data.balance
            })
          })
          .catch(error => {
            console.error(error);
          });
    },
    getCheckResultHistory(user_id) {
      axios.post('api/v1/check-result/history/', {user_id: user_id})
          .then(response => {
            this.$store.commit("setStateProperty", {
              property: "check_history", value: response.data
            })
          })
          .catch(error => {
            console.error(error);
          });
    },
    getPaymentHistory(user_id) {
      axios.post('api/v1/payment/history/', {user_id: user_id})
          .then(response => {
            this.$store.commit("setStateProperty", {
              property: "payment_history", value: response.data
            })
          })
          .catch(error => {
            console.error(error);
          });
    },
    copyData(data) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium')
      navigator.clipboard.writeText(data)
          .then(() => {
            this.success_snackbar_show = true;
            this.success_snackbar_text = store.state.text[this.lang].success_copy_text;
          })
          .catch((error) => {
            console.error("Failed to copy text:", error);
          });
    },
  }
}
</script>

<style scoped>

@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

html, body {
  font-family: 'SF Pro', sans-serif !important;
  color: black;
  background: white !important;
  touch-action: manipulation !important;
}

.pre-subtitle {
  white-space: pre-line !important;
}

.custom-input {
  font-size: 0.9rem;
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding-inline: 0.5em;
  padding-block: 0.7em;
  border: 1px solid #E3E4E6;
}

.custom-input::placeholder {
  color: #777;
  border: none !important;
}

custom-input:focus {
  outline: none !important;
}
textarea:focus, input:focus{
  outline: none !important;
}

</style>