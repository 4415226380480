import {createRouter, createWebHistory} from "vue-router"
import BalancePage from "@/pages/BalancePage.vue";
import SettingsPage from "@/pages/SettingsPage.vue";
import LanguagePage from "@/pages/LanguagePage.vue";
import PreviewPage from "@/pages/PreviewPage.vue";
import CheckResultPage from "@/pages/CheckResultPage.vue";
import CheckHistoryPage from "@/pages/CheckHistoryPage.vue";
import FriendsPage from "@/pages/FriendsPage.vue";
import CryptoPaymentPage from "@/pages/CryptoPaymentPage.vue";
import CryptoPaymentExpiredPage from "@/pages/CryptoPaymentExpiredPage.vue";
import HomePage from "@/pages/HomePage.vue";


const routes = [
    {
        path: "/",
        name: "Home",
        component: HomePage,
    },
    {
        path: "/cryptoPayment",
        name: "CryptoPayment",
        component: CryptoPaymentPage,
    },
    {
        path: "/paymentExpired",
        name: "CryptoPaymentExpired",
        component: CryptoPaymentExpiredPage,
    },
    {
        path: "/language",
        name: "Language",
        component: LanguagePage,
    },
    {
        path: "/checkHistory",
        name: "CheckHistory",
        component: CheckHistoryPage,
    },
    {
        path: "/checkResult",
        name: "CheckResult",
        component: CheckResultPage,
    },
    {
        path: "/preview",
        name: "Preview",
        component: PreviewPage,
    },
    {
        path: "/balance",
        name: "Balance",
        component: BalancePage,
    },
    {
        path: "/settings",
        name: "Settings",
        component: SettingsPage,
    },
    {
        path: "/friends",
        name: "Friends",
        component: FriendsPage,
    }
]

const router = createRouter({
    routes,
    history: createWebHistory()
    })

export default router;
