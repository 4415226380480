import { createApp } from 'vue'
import App from './App'
import router from "@/router";
import store from "@/store";
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
// import * as labsComponents from 'vuetify/labs/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import axios from "axios";
import DateFnsAdapter from '@date-io/date-fns'
import enUS from 'date-fns/locale/en-US'
import ru from 'date-fns/locale/ru'

axios.defaults.baseURL = '/';

const vuetify = createVuetify({
  components,
  directives,
  date: {
    adapter: DateFnsAdapter,
    locale: {
      en: enUS,
      ru: ru,
    },
  }
})


const app = createApp(App)
app.use(router).use(vuetify).use(store).mount('#app');

