<template>
  <v-app class="bg-white" v-if="check_progress">
    <v-sheet style="height: 30vh"></v-sheet>
    <div class="text-center">
      <v-progress-circular
          :model-value="value"
          :rotate="360"
          :size="200"
          :width="10"
          color="#F31549"
      >
        <b style="color: black">{{ value }} %</b>

      </v-progress-circular>
    </div>
  </v-app>
  <load-component v-else-if="loading"></load-component>
  <v-app class="bg-white" v-else>
    <div class="content">
      <v-container>
        <v-row justify="center">
          <v-col cols="2">
            <v-btn @click="pushToHomePage" class="border-12" height="34" color="#F0F2F6" variant="flat" icon="mdi-chevron-left">
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-btn @click="pushToBalancePage" class="border-12" height="34" variant="flat" color="#F0F2F6">
              <template v-slot:prepend>
                <v-icon color="#F31549">mdi-star-four-points</v-icon>
              </template>
              <b>{{ store.state.balance }}</b>
            </v-btn>
          </v-col>
          <v-col cols="1">
          </v-col>
        </v-row>

        <v-sheet class="bg-transparent" height="10px"></v-sheet>

        <v-card variant="flat">
          <v-card-subtitle class="text-start">
            <v-chip @click="copyData(check_result.id)" color="#F31549" size="x-small"> {{check_result.id}}</v-chip>
          </v-card-subtitle>
          <v-card-title class="roboto-bold" style="font-size: 24px; line-height: 28px">
            {{ store.state.text[lang].check_result.title_1 }}
          </v-card-title>
          <v-card-subtitle>
            <v-row justify="space-between">
              <v-col cols="6">
                {{ store.state.text[lang].check_result.subtitle_1 }} {{ checkedAt }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4">
                {{ store.state.text[lang].check_result.subtitle_2 }} <v-icon color="#BAC6D4" size="small">mdi-star-four-points</v-icon> 1
              </v-col>
            </v-row>
          </v-card-subtitle>
        </v-card>

        <v-sheet class="bg-transparent" height="20px"></v-sheet>
        <v-row no-gutters align="center">
          <v-col cols="auto">
            <v-chip color="#F0F2F6" variant="flat">
              <template v-slot:prepend>
                <div v-if="check_result.address.network == 'BTC'">
                  <svg width="24" height="24" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_189_2738)">
                      <path d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z" fill="#F7931A"/>
                      <path d="M18.3922 11.515C18.6277 9.943 17.4299 9.09775 15.7934 8.53375L16.3244 6.40375L15.0284 6.08125L14.5109 8.155C14.1704 8.0695 13.8209 7.99 13.4722 7.9105L13.9934 5.82325L12.6974 5.5L12.1664 7.62925C11.8844 7.56475 11.6069 7.50175 11.3384 7.43425L11.3399 7.4275L9.55191 6.98125L9.20691 8.36575C9.20691 8.36575 10.1692 8.58625 10.1489 8.59975C10.6739 8.731 10.7684 9.07825 10.7527 9.35425L10.1482 11.7805C10.1842 11.7895 10.2307 11.803 10.2832 11.8232L10.1459 11.7895L9.29841 15.1885C9.23391 15.3475 9.07116 15.5867 8.70366 15.496C8.71716 15.5147 7.76166 15.2612 7.76166 15.2612L7.11816 16.7448L8.80566 17.1655C9.11916 17.2443 9.42666 17.3267 9.72891 17.404L9.19266 19.558L10.4879 19.8805L11.0189 17.7505C11.3729 17.8457 11.7164 17.9342 12.0524 18.0182L11.5229 20.1392L12.8189 20.4618L13.3552 18.3123C15.5662 18.7308 17.2282 18.562 17.9279 16.5625C18.4919 14.953 17.9002 14.0238 16.7369 13.4185C17.5844 13.2235 18.2219 12.6663 18.3922 11.515ZM15.4297 15.6685C15.0299 17.2788 12.3187 16.408 11.4397 16.1898L12.1522 13.336C13.0312 13.5557 15.8489 13.99 15.4297 15.6685ZM15.8309 11.4918C15.4657 12.9565 13.2097 12.2118 12.4784 12.0295L13.1234 9.442C13.8547 9.62425 16.2119 9.964 15.8309 11.4918Z" fill="white"/>
                    </g>
                    <rect x="0.5" y="0.5" width="25" height="25" rx="12.5" stroke="#EAEBEB"/>
                    <defs>
                      <clipPath id="clip0_189_2738">
                        <rect x="1" y="1" width="24" height="24" rx="12" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div v-else-if="check_result.address.network == 'ETH'">
                  <svg width="24" height="24" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_189_2742)">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4531 1C15.8265 1 18.1466 1.70379 20.12 3.02236C22.0934 4.34094 23.6314 6.21509 24.5397 8.4078C25.4479 10.6005 25.6856 13.0133 25.2225 15.3411C24.7595 17.6689 23.6166 19.8071 21.9384 21.4853C20.2602 23.1635 18.122 24.3064 15.7942 24.7694C13.4664 25.2324 11.0536 24.9948 8.86092 24.0866C6.66821 23.1783 4.79407 21.6402 3.47549 19.6668C2.15691 17.6935 1.45313 15.3734 1.45312 13C1.45312 9.8174 2.71741 6.76516 4.96784 4.51472C7.21828 2.26428 10.2705 1 13.4531 1V1Z" fill="white"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4531 3.57666L16.6102 8.29375L19.7673 13.0119L13.4531 16.193L7.13892 12.9999L10.296 8.28175L13.4531 3.57666ZM13.4531 17.4639L7.4891 14.2818L13.4531 22.4232L19.4171 14.2818L13.4531 17.4639Z" fill="#343434"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4531 3.57666L16.6102 8.28175L19.7673 12.9999L13.4531 10.6163V3.57666Z" fill="#343434"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4531 17.4639L19.4291 14.2817L13.4531 22.4352V17.4639Z" fill="#313131"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4531 10.6162L19.7673 12.9998L13.4531 16.1809V10.6162Z" fill="#151515"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4531 3.57666L10.296 8.28175L7.13892 12.9999L13.4531 10.6163V3.57666Z" fill="#8C8C8C"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4531 17.4639L7.48914 14.2817L13.4531 22.4352V17.4639Z" fill="#8A8A8A"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4531 10.6162L7.13892 12.9998L13.4531 16.1809V10.6162Z" fill="#353535"/>
                    </g>
                    <rect x="1" y="0.5" width="25" height="25" rx="12.5" stroke="#EAEBEB"/>
                    <defs>
                      <clipPath id="clip0_189_2742">
                        <rect x="1.5" y="1" width="24" height="24" rx="12" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div v-else-if="check_result.address.network == 'TRX'">
                  <svg width="24" height="24" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_189_2747)">
                      <path d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z" fill="#EF0027"/>
                      <path d="M17.449 8.43487L6.625 6.44287L12.3212 20.7769L20.2585 11.1064L17.449 8.43487ZM17.275 9.31237L18.931 10.8866L14.4025 11.7064L17.275 9.31237ZM13.4185 11.5421L8.6455 7.58362L16.447 9.01912L13.4185 11.5421ZM13.0787 12.2426L12.3003 18.6776L8.104 8.11537L13.0787 12.2419V12.2426ZM13.7988 12.5839L18.814 11.6764L13.0615 18.6836L13.7988 12.5839Z" fill="white"/>
                    </g>
                    <rect x="0.5" y="0.5" width="25" height="25" rx="12.5" stroke="#EAEBEB"/>
                    <defs>
                      <clipPath id="clip0_189_2747">
                        <rect x="1" y="1" width="24" height="24" rx="12" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </template>
              <b class="roboto-bold ml-1">{{ check_result.address.network }}</b>
            </v-chip>
          </v-col>
          <v-col cols="auto">
            <v-btn @click="copyData(this.check_result.address.address)" class="border-12 text-none text-decoration-none"  variant="text">
              {{ checkedAddress }}
              <template v-slot:append>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 9.75C7.5 8.50736 8.50736 7.5 9.75 7.5H18.75C19.9926 7.5 21 8.50736 21 9.75V18.75C21 19.9926 19.9926 21 18.75 21H9.75C8.50736 21 7.5 19.9926 7.5 18.75V9.75Z" fill="#BAC6D4"/>
                  <path d="M5.25 3C4.00736 3 3 4.00736 3 5.25V14.25C3 15.4926 4.00736 16.5 5.25 16.5L5.25 9.75C5.25 7.26472 7.26472 5.25 9.75 5.25H16.5C16.5 4.00736 15.4926 3 14.25 3H5.25Z" fill="#BAC6D4"/>
                </svg>

              </template>
            </v-btn>
          </v-col>
        </v-row>

      <v-sheet height="20px" class="bg-transparent"></v-sheet>
      <div class="text-center">
        <v-col cols="12">
          <v-progress-circular
              :model-value="riskScoreValue"
              :rotate="-90"
              :size="200"
              :width="10"
              :color="riskScoreColor"
              bg-color="white"
          >

            <br>
            <v-card variant="text" class="text-center">
              <v-card-title class="roboto-bold text-black" >
                <h2>{{ riskScoreInPercent }}%</h2>
              </v-card-title>
              <v-card-title class="roboto-bold">
                <b>{{ riskScoreString }}</b>
              </v-card-title>
            </v-card>
          </v-progress-circular>
        </v-col>
      </div>

        <v-card v-if="check_result_exposure.exposure.length == 0" variant="flat">
          <v-card-title class="roboto-bold">
            {{ store.state.text[lang].check_result.title_3 }}
          </v-card-title>
        </v-card>
      <v-card variant="flat" v-else>
        <v-card-title class="roboto-bold">
          {{ store.state.text[lang].check_result.title_2 }}
        </v-card-title>
        <v-card-item v-for="exposure in check_result_exposure.exposure" :key="exposure">
          <v-progress-linear
              class="border-12"
              height="30px"
              :color="scoreColor(scoreInPercent(exposure.value_share))"
              bg-color="black"
              :model-value="scoreInPercent(exposure.value_share)"
          >
            <v-row align="center">
              <v-col cols="1">
              </v-col>
              <v-col cols="auto">
                <b class="roboto-bold" style="font-size: 16px; color: black">
                  {{ scoreInPercent(exposure.value_share) }}%
                </b>
              </v-col>
              <v-col cols="auto">
                <b class="roboto-bold" style="font-size: 16px; color: black">
                  {{exposure.entity_category}}
                </b>
              </v-col>
            </v-row>
          </v-progress-linear>
        </v-card-item>
      </v-card>
      </v-container>
    </div>

    <v-bottom-navigation
        height="200px"
        base-color="#1A1C25"
        color="white"
    >
      <v-container>
        <v-row justify="center" no-gutters>
          <v-col cols="5">
            <v-btn
                @click="checkAgain()"
                width="100%"
                height="48px"
                class="border-12 text-none"
                style="background: linear-gradient(-90deg, #E32B25, #D6D841); font-size: 16px"
                variant="flat"
            >
              {{ store.state.text[lang].check_result.btn_1 }}
            </v-btn>
          </v-col>
          <v-col cols="7" class="text-end">
            <v-btn
                :disabled="!check_result.pdf_report"
                @click="downloadFile(check_result.pdf_report)"
                width="95%"
                :stacked="false"
                height="48px"
                class="border-12 text-none"
                prepend-icon="mdi-tray-arrow-down"
                style="background: #F1F1F1; font-size: 16px; color: #353748"
                variant="flat"
            >
              {{ store.state.text[lang].check_result.btn_2 }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

    </v-bottom-navigation>

    <v-snackbar
        style="margin-bottom: 100px"
        v-model="success_snackbar_show"
        :timeout="snackbar_timeout"
        color="#1d1d1d"
        rounded="lg"
        location="center"
        variant="flat"
    >
      ✅ {{ success_snackbar_text }}
    </v-snackbar>

  </v-app>

</template>

<script>
import axios from "axios";
// import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader';
// import BottomNavComponent from "@/components/BottomNavComponent.vue";
import LoadComponent from "@/components/LoadComponent.vue";
import store from "@/store";
import WAValidator from "multicoin-address-validator";
export default {
  name: "CheckResultPage",
  components: {
    LoadComponent
  },
  data: () => ({
    loading: true,
    success_snackbar_text: null,
    success_snackbar_show: false,
    snackbar_timeout: 3000,
    lang: "en",
    check_progress: false,
    interval: {},
    value: 0,
    item: {
      label: "file.pdf",
      url: "https://e1b9f417-f9a0f054-5354-4a47-83f4-abcdc5dcd736.s3.timeweb.cloud/report_d49d2883-a212-4a56-8dcc-3a27bceb2673.pdf"
    },
    fileUrl: null,
    check_result: {
      id: null,
      address: {
        network: null,
        address: "",
      },
      risk_score: 0.5,
      checked_at: ""
    },
    check_result_exposure: {
      exposure: []
    },
    token_items: [
      {
        index: 0,
        name: "Bitcoin",
      },
      {
        index: 1,
        name: "Ethereum",
      },
      {
        index: 2,
        name: "Tron",
      },
      {
        index: 3,
        name: "Litecoin",
      }
    ]
  }),
  computed: {
    store() {
      return store
    },
    riskScoreInPercent() {
      return (this.check_result.risk_score * 100).toFixed(2); // округляем до 2 знаков после запятой
    },
    riskScoreValue() {
      return (this.riskScoreInPercent / 2).toFixed(2); // переводим в новую шкалу
    },
    riskScoreString() {
      if (this.riskScoreInPercent > 66) {
        return store.state.text[this.lang].check_result.risk_high;
      } else if (this.riskScoreInPercent > 33) {
        return store.state.text[this.lang].check_result.risk_medium;
      } else {
        return store.state.text[this.lang].check_result.risk_low;
      }
    },
    riskScoreColor() {
      if (this.riskScoreInPercent > 66) {
        return 'red';
      } else if (this.riskScoreInPercent > 33) {
        return 'orange';
      } else {
        return 'green';
      }
    },
    checkedAt() {
      const date = new Date(this.check_result.checked_at);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
      const year = date.getFullYear();

      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return`${day}.${month}.${year} ${hours}:${minutes}`;
    },
    checkedAddress() {
      const prefix = this.check_result.address.address.slice(0, 5);
      const suffix = this.check_result.address.address.slice(-5);
      return `${prefix}...${suffix}`;
    },
  },
  mounted() {
    window.Telegram.WebApp.ready()
    window.Telegram.WebApp.BackButton.hide()
    window.Telegram.WebApp.MainButton.hide()
    window.Telegram.WebApp.disableVerticalSwipes()
    this.checkInitData({_auth: window.Telegram.WebApp.initData})

    if (localStorage.getItem('lang')) {
      this.lang = localStorage.getItem('lang')
    }
    else {
      localStorage.setItem('lang', this.lang)
    }

    window.Telegram.WebApp.expand()
    this.getCheckResult(this.$route.query.result_id)
    this.getCheckResultRisks(this.$route.query.result_id)
  },
  beforeUnmount () {
    clearInterval(this.interval)
  },
  methods: {
    downloadFile(url) {
      window.Telegram.WebApp.downloadFile({
            url: url,
            file_name: `report_${this.$route.query.result_id}.pdf`
          })
    },
    scoreInPercent(score) {
      return (score * 100).toFixed(2);
    },
    scoreColor(score) {
      if (score > 66) {
        return 'red';
      } else if (score > 33) {
        return 'orange';
      } else {
        return 'green';
      }
    },
    checkInitData(data) {
      axios.post('api/v1/check-init-data/', data)
          .then(response => {
            this.user_id = response.data.user.id
          })
          .catch(error => {
            console.error(error);
          });
    },
    getCheckResult(result_id) {
      axios.get('api/v1/check-result/', {
        params: {
          result_id: result_id
        }})
          .then(response => {
            this.check_result = response.data
            this.loading = false
          })
          .catch(error => {
            console.error(error);
          });
    },
    formatString(data) {
      const prefix = data.slice(0, 10);
      const suffix = data.slice(-5);
      return `${prefix}...${suffix}`;
    },
    getCheckResultRisks(result_id) {
      axios.get('api/v1/check-result/exposure/', {
        params: {
          result_id: result_id
        }})
          .then(response => {
            console.log(response.data)
            this.check_result_exposure = response.data
          })
          .catch(error => {
            console.error(error);
          });
    },
    pushToHomePage() {
      this.$router.push({ name: 'Home'});
    },
    checkAgain() {
      window.Telegram.WebApp.showConfirm(
          store.state.text[this.lang].confirm_check_again, (confirm) => {
            if (confirm) {
              this.checkAddress()
            }
          })
    },
    checkAddressFalse() {
      this.check_progress = false
    },
    checkAddress() {
      if (!this.check_result.address.network) {
        this.error_snackbar_text = store.state.text[this.lang].home.blockchain_network_error
        this.error_snackbar_show = true
        return
      }

      var valid = WAValidator.validate(this.check_result.address.address, this.check_result.address.network, );
      if (valid) {
        this.check_progress = true
        axios.post('api/v1/check-address/', {
          user_id: store.state.user.id,
          address: this.check_result.address.address,
          network: this.check_result.address.network
        })
            .then((response) => {
              setTimeout(function () {
                this.pushToCheckResult(response.data.id)
              }.bind(this), 3000)
              setTimeout(this.checkAddressFalse, 4000)
            })
            .catch(error => {
              this.checkAddressFalse()
              this.error_snackbar_text = error.response.data.msg
              this.error_snackbar_show = true
              console.error(error);
            });
      }
      else {
        this.error_snackbar_text = store.state.text[this.lang].home.valid_error
        this.error_snackbar_show = true
        this.input_error = true
      }
    },
    copyData(data) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium')
      navigator.clipboard.writeText(data)
          .then(() => {
            this.success_snackbar_show = true;
            this.success_snackbar_text = store.state.text[this.lang].success_copy_text;
          })
          .catch((error) => {
            console.error("Failed to copy text:", error);
          });
    },
    pushToBalancePage() {
      this.$router.push({name: 'Balance'});
    },
  }
}
</script>

<style scoped>

@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

html, body {
  font-family: 'SF Pro', sans-serif !important;
  color: black;
  background: white !important;
  touch-action: manipulation !important;
}

.pre-subtitle {
  white-space: pre-line !important;
}

</style>