<template>
  <v-app class="bg-white">
    <div class="content">
      <v-container>
        <v-sheet class="bg-transparent" height="30px"></v-sheet>
        <h3 class="roboto-bold">{{ store.state.text[lang].settings.title }}</h3>
        <v-sheet class="bg-transparent" height="20px"></v-sheet>
        <v-select
            @update:modelValue="onChangeLang"
            color="black"
            bg-color="white"
            label="Language"
            rounded="lg"
            :items="items"
            item-title="title"
            item-value="value"
            variant="outlined"
            v-model="language"
            menu-icon="mdi-chevron-down"
            hide-details
        ></v-select>

        <v-sheet class="bg-transparent" height="20px"></v-sheet>
        <hr>
        <v-sheet class="bg-transparent" height="20px"></v-sheet>
        <v-card variant="flat">
          <v-card-title class="roboto-bold">
            AML Check Result
          </v-card-title>
          <v-card-subtitle class="pre-subtitle">
            Some text about generate API key and past them into your app to check AML
          </v-card-subtitle>
        </v-card>
        <v-sheet class="bg-transparent" height="20px"></v-sheet>
        <v-btn class="border-12 text-none roboto-bold" variant="flat" color="#F31549">
          Generate new key
        </v-btn>
        <v-sheet class="bg-transparent" height="130px"></v-sheet>
      </v-container>
    </div>

    <v-snackbar
        style="margin-bottom: 100px"
        v-model="error_snackbar_show"
        :timeout="snackbar_timeout"
        color="#1d1d1d"
        rounded="lg"
        location="center"
        variant="flat"
    >
      ❌ {{ error_snackbar_text }}
    </v-snackbar>
    <bottom-nav-component btn_5_color="#F31549" :lang="lang"></bottom-nav-component>
  </v-app>
</template>

<script>

import BottomNavComponent from "@/components/BottomNavComponent.vue";
import store from "../store";
import axios from "axios";

export default {
  name: "HomePage",
  computed: {
    store() {
      return store
    }
  },
  components: {BottomNavComponent},
  data: () => ({
    error_snackbar_show: false,
    error_snackbar_text: null,
    snackbar_timeout: 3000,
    lang: "en",
    language: null,
    language_index: 0,
    initialized: false,
    lastChangeTime: 0,
    changeCooldown: 5000,
    items: [{title: '🇬🇧 English', value: "en"}, {title: '🇷🇺 Русский', value: "ru"}]
  }),
  mounted() {
    this.setLanguageFromLocalStorage()
    if (!store.state.loaded) {
      this.checkInitData({_auth: window.Telegram.WebApp.initData})
    }
  },
  methods: {
    checkInitData(data) {
      axios.post('api/v1/check-init-data/', data)
          .then(response => {
            this.$store.commit("setStateProperty", {
              property: "user", value: response.data.user
            })
            this.loadUserData(response.data.user.id)
          })
          .catch(error => {
            console.error(error);
          });
    },
    loadUserData(user_id) {
      this.getUser(user_id)
      this.getCheckResultHistory(user_id)
      this.getPaymentHistory(user_id)
      this.getReferrals(user_id)
      this.$store.commit("setStateProperty", {
        property: "loaded", value: true
      })
      this.loading = false

      window.Telegram.WebApp.ready()
    },
    getUser(user_id) {
      axios.post('api/v1/getUser', {user_id: user_id})
          .then(response => {
            this.$store.commit("setStateProperty", {
              property: "balance", value: response.data.balance
            })
          })
          .catch(error => {
            console.error(error);
          });
    },
    getReferrals(user_id) {
      axios.post('api/v1/user/get-referrals/', {user_id: user_id})
          .then(response => {
            this.$store.commit("setStateProperty", {
              property: "friends", value: response.data
            })
          })
          .catch(error => {
            console.error(error);
          });
    },
    getCheckResultHistory(user_id) {
      axios.post('api/v1/check-result/history/', {user_id: user_id})
          .then(response => {
            this.$store.commit("setStateProperty", {
              property: "check_history", value: response.data
            })
          })
          .catch(error => {
            console.error(error);
          });
    },
    getPaymentHistory(user_id) {
      axios.post('api/v1/payment/history/', {user_id: user_id})
          .then(response => {
            this.$store.commit("setStateProperty", {
              property: "payment_history", value: response.data
            })
          })
          .catch(error => {
            console.error(error);
          });
    },
    onChangeLang(value) {
      const currentTime = Date.now();

      if (currentTime - this.lastChangeTime < this.changeCooldown) {
        this.error_snackbar_text = store.state.text[value].settings.cooldown
        this.error_snackbar_show = true
        window.Telegram.WebApp.HapticFeedback.notificationOccurred("error")
      }
      else {
        window.Telegram.WebApp.HapticFeedback.notificationOccurred("success")
        this.lang = value
        localStorage.setItem('lang', value)
        this.lastChangeTime = currentTime;
        setTimeout(() => {
        }, this.changeCooldown);

        axios.post('api/v1/user/change-language/', {user_id: store.state.user.id, language: value})
            .catch(error => {
              console.error(error);
            });
    }
    },
    setLanguageFromLocalStorage() {
      const langValue = localStorage.getItem('lang')
      const foundLanguage = this.items.find(item => item.value === langValue);
      this.language = foundLanguage || this.items[0].title;
      this.lang = langValue
    }
  },
}

</script>

<style scoped>

.time-left {
  position: absolute;
  top: 10px;
  width: 100%;
}

.pre-subtitle {
  white-space: pre-line !important;
}


</style>